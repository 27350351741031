import _ from 'lodash';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { deserializeNodeIdToAnswerPathMap, evaluateConditions } from '@breathelife/questionnaire-engine';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import {
  Conditions,
  DEFAULT_TIMEZONE_NAME,
  LocalizedInsuranceProduct,
  OutcomeCode,
  QuotedInsuranceProduct,
  QuotePerProduct,
  Timezone,
} from '@breathelife/types';

import { CarrierContext } from '../../Context/CarrierContext';
import { sortQuotedProducts } from '../../Helpers/sortQuotedProducts';
import { submitUrlForm } from '../../Helpers/submitForm';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { text } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { ApplicationState } from '../../Models/ApplicationState';
import { ModalType } from '../../Models/Layout';
import { LegacyProductInfo, LegacyQuotedProduct } from '../../Models/Product';
import { useGetPointOfSaleDecisionsOutcomeForSingleInsured } from '../../ReactQuery/PointOfSaleDecisions/pointOfSaleDecisions.queries';
import * as applicationOperations from '../../Redux/InsuranceApplication/InsuranceApplicationOperations';
import { layoutSlice } from '../../Redux/Layout/LayoutSlice';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import * as SubmissionService from '../../Services/SubmissionService';
import { FallbackQuoterView } from './FallbackQuoterView';
import { QuoterView as DefaultQuoterView } from './QuoterView';

// This is used for custom product page typing in carrier context. The default product page typing
// live in the Consumer-flow ProductPage itself.
export type QuoterPageProps = {
  application?: ApplicationState;
  applicationAssignee?: ApplicationAssignee;
  selfServeBlockedIf?: Conditions;
  popup: ReactElement | null;
  recommendedCoverage?: number;
  isSelfServeBlocked: boolean;
  isLoading: boolean;
  onBack: () => void;
  onScheduleButtonClick: () => void;
};

function buildQuotedProducts(
  products: (LegacyProductInfo | LocalizedInsuranceProduct)[],
  quotes: QuotePerProduct,
): LegacyQuotedProduct[] | QuotedInsuranceProduct[] {
  return products.map((product) => ({ ...product, premium: quotes[product.id] })) as
    | LegacyQuotedProduct[]
    | QuotedInsuranceProduct[];
}

type Props = {
  title?: string;
  subtitle?: string;
  selfServeBlockedIf?: Conditions;
  onApply: (answers: { productId: string; coverageAmount: number }) => void;
  onCoverageAmountChange: (coverageAmount: number) => void;
  onBack: () => void;
  isSimpleQuoter?: boolean;
  onUseNeedsAnalysis: (useNeedsAnalysis: boolean) => void;
};

const DEFAULT_COVERAGE_AMOUNT = 250_000;

export function QuoterViewContainer(props: Props): ReactElement | null {
  const dispatch = useDispatch();

  const {
    title,
    subtitle,
    onApply: onApplyProp,
    onBack: navigateToPreviousStep,
    selfServeBlockedIf,
    isSimpleQuoter,
    onUseNeedsAnalysis,
  } = props;
  const { features, enableLoadProductInformationFromDb, enableSalesDecisionRules, carrierInfo } =
    useContext(CarrierContext);

  const applicationState = useCxSelector((store) => store.consumerFlow.insuranceApplication);
  const { recommendedCoverage, insuranceApplication: application } = applicationState;
  const insuranceApplicationId = application?.id ?? null;

  const applicationAssignee = useCxSelector((store) => store.consumerFlow.applicationAssignee.assignee);

  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [selectedCoverageAmount, setSelectedCoverageAmount] = useState<number>();
  const [hasInitializedQuotes, setHasInitializedQuotes] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(true);

  const { data: outcome } = useGetPointOfSaleDecisionsOutcomeForSingleInsured(application?.id, {
    staleTime: 0, //Always refetch.
  });

  const eligibleProducts = useMemo(() => {
    const products = applicationState.products || [];

    if (enableLoadProductInformationFromDb) {
      return products;
    }

    return (products as LegacyProductInfo[]).filter((product) => product.isEligible);
  }, [applicationState.products, enableLoadProductInformationFromDb]);

  const quotedProducts = useMemo(() => {
    const newProducts = [...eligibleProducts];
    const sortedProducts = newProducts.sort(sortQuotedProducts);
    return buildQuotedProducts(sortedProducts, applicationState.quotes ?? {});
  }, [eligibleProducts, applicationState.quotes]);

  const quoterFeatureEnabled = features.productPage.enabled && features.productPage.quoter?.enabled;

  const isSelfServeBlocked = useCallback(() => {
    if (enableSalesDecisionRules) {
      return outcome === OutcomeCode.blockedAtQuoter;
    } else {
      if (!features.productPage.enabled) return false;
      if (!selfServeBlockedIf) return false;

      if (outcome && outcome === OutcomeCode.blockedAtQuoter) return true;

      const nodeIdToAnswerPathMap = application?.answersDataStructure
        ? deserializeNodeIdToAnswerPathMap(application.answersDataStructure)
        : new Map();

      const timezoneResult = Timezone.from(application?.timezone || DEFAULT_TIMEZONE_NAME);
      if (timezoneResult.success === false) {
        throw new Error('Could not create a timezone with the applications one or the default.');
      }

      return evaluateConditions(
        selfServeBlockedIf,
        application?.answers ?? {},
        nodeIdToAnswerPathMap,
        {},
        timezoneResult.value,
        application?.currentDateOverride || null,
      );
    }
  }, [features, application, selfServeBlockedIf, outcome, enableSalesDecisionRules]);

  const fetchQuotes = useCallback(
    async (coverageAmount: number) => {
      if (!application || !coverageAmount) return;

      setIsLoadingQuotes(true);
      await dispatch(applicationOperations.getQuotes(application.id, coverageAmount));
      setIsLoadingQuotes(false);
    },
    [application, dispatch],
  );

  const onApplyThroughAdvisor = useCallback(() => {
    dispatch(layoutSlice.actions.setModalState({ modalState: { isOpen: true, type: ModalType.applyThroughAdvisor } }));
  }, [dispatch]);

  const onApply = useCallback(
    async (data: LegacyQuotedProduct | QuotedInsuranceProduct) => {
      if (!application || !data.premium || !data.productId || !selectedCoverageAmount) return;

      try {
        const response = await SubmissionService.submitNeedsAnalysis({
          productId: data.productId,
          premium: data.premium,
          appId: application.id,
          coverageAmount: selectedCoverageAmount,
        });

        TypewriterTracking.acceptedQuote({
          hashedId: hash(insuranceApplicationId),
          selectedProducts: [
            {
              productId: data.productId,
              price: data.premium,
              carrierId: data.carrierId,
              coverageAmount: selectedCoverageAmount,
              isFeatured: 'featured' in data && data.featured,
            },
          ],
        });

        if (response.redirectUrl) {
          submitUrlForm(response.redirectUrl);
        } else {
          if (isSimpleQuoter) {
            onUseNeedsAnalysis(false);
          }
          onApplyProp({ productId: data.productId, coverageAmount: selectedCoverageAmount });
        }
      } catch (err: any) {
        dispatch(notificationSlice.actions.setError({ message: text('product.error.submission') }));
      }
    },
    [
      application,
      dispatch,
      insuranceApplicationId,
      selectedCoverageAmount,
      onApplyProp,
      isSimpleQuoter,
      onUseNeedsAnalysis,
    ],
  );

  //@ts-ignore
  const sliderMin = _.min(_.map(eligibleProducts, 'coverageRange.min')) as number;
  //@ts-ignore
  const sliderMax = _.max(_.map(eligibleProducts, 'coverageRange.max')) as number;

  // Reset products on mount
  useEffect(() => {
    dispatch(applicationOperations.resetProducts());
  }, [dispatch]);

  useEffect(() => {
    function showLoadingPageOnInitialLoad(): void {
      const productTransitionLoadingDuration = carrierInfo.productTransitionLoadingDuration?.life;
      if (!productTransitionLoadingDuration) {
        setShowLoadingPage(false);
        return;
      }

      setTimeout(() => setShowLoadingPage(false), productTransitionLoadingDuration);
    }

    showLoadingPageOnInitialLoad();
  }, [carrierInfo.productTransitionLoadingDuration]);

  // Setup: set the selected coverage amount (the initial slider amount) on mount
  useEffect(() => {
    if (_.isUndefined(recommendedCoverage) || _.isUndefined(sliderMax) || _.isUndefined(sliderMin)) return;
    if (!isSimpleQuoter && recommendedCoverage === 0) return;

    let sliderValue = (isSimpleQuoter && DEFAULT_COVERAGE_AMOUNT) || application?.faceValue || recommendedCoverage;

    if (sliderValue > sliderMax) {
      sliderValue = sliderMax;
    } else if (sliderValue < sliderMin) {
      sliderValue = sliderMin;
    }

    setSelectedCoverageAmount(sliderValue);
  }, [recommendedCoverage, sliderMin, sliderMax, application, isSimpleQuoter]);

  // Setup: get recommended Coverage and products on mount
  useEffect(() => {
    if (!insuranceApplicationId || !application) return;
    dispatch(applicationOperations.getRecommendedCoverage(insuranceApplicationId));

    if (quoterFeatureEnabled) {
      dispatch(applicationOperations.getProducts(insuranceApplicationId));
    }
  }, [dispatch, application, insuranceApplicationId, quoterFeatureEnabled, enableLoadProductInformationFromDb]);

  // Setup: get Quotes on mount once we have the eligible products
  useEffect(() => {
    if (hasInitializedQuotes) return;
    if (quoterFeatureEnabled && selectedCoverageAmount && !_.isEmpty(eligibleProducts)) {
      setHasInitializedQuotes(true);
      void fetchQuotes(selectedCoverageAmount);
    }
  }, [quoterFeatureEnabled, fetchQuotes, selectedCoverageAmount, eligibleProducts, hasInitializedQuotes]);

  useEffect(() => {
    if (!recommendedCoverage || !selectedCoverageAmount) return;
    if (quoterFeatureEnabled && eligibleProducts.length === 0) return;
    const someProductHasPremium = quotedProducts.some((product) => !_.isNil(product.premium));
    if (quoterFeatureEnabled && !someProductHasPremium) return;

    const recommendedProductProps = quotedProducts.map((product) => ({
      coverageAmount: recommendedCoverage,
      productId: product.id,
      price: product.premium,
      carrierId: product.carrierId,
      featured: 'featured' in product && product.featured,
    }));

    TypewriterTracking.viewedQuote({
      recommendedProducts: recommendedProductProps,
      hashedId: hash(insuranceApplicationId),
    });
  }, [
    insuranceApplicationId,
    eligibleProducts,
    recommendedCoverage,
    selectedCoverageAmount,
    quotedProducts,
    quoterFeatureEnabled,
  ]);

  useEffect(() => {
    return () => {
      dispatch(applicationOperations.resetQuotes());
    };
  }, [dispatch]);

  if (enableLoadProductInformationFromDb && (eligibleProducts.length === 0 || _.isNil(selectedCoverageAmount))) {
    return (
      <FallbackQuoterView
        title={title}
        subtitle={subtitle}
        isSelfServeBlocked={isSelfServeBlocked()}
        showLoadingPage={showLoadingPage}
        onApplyThroughAdvisor={onApplyThroughAdvisor}
      />
    );
  }

  if (_.isNil(selectedCoverageAmount)) return null;
  return (
    <DefaultQuoterView
      title={title}
      subtitle={subtitle}
      isSimpleQuoter={isSimpleQuoter}
      applicationAssignee={applicationAssignee}
      application={application}
      recommendedCoverage={recommendedCoverage}
      quotedProducts={quotedProducts}
      isSelfServeBlocked={isSelfServeBlocked()}
      isLoadingQuotes={isLoadingQuotes}
      showLoadingPage={showLoadingPage}
      selectedCoverageAmount={selectedCoverageAmount}
      setSelectedCoverageAmount={setSelectedCoverageAmount}
      coverageAmountOptions={{ min: sliderMin, max: sliderMax }}
      onApplyThroughAdvisor={onApplyThroughAdvisor}
      onBack={navigateToPreviousStep}
      onApply={onApply}
      fetchQuotes={fetchQuotes}
      onCoverageAmountChange={props.onCoverageAmountChange}
      onUseNeedsAnalysis={onUseNeedsAnalysis}
    />
  );
}
